@use 'vars' as *;
@import url('https://fonts.googleapis.com/css2?family=Yantramanav:wght@400;500;700;900&display=swap');

body {
    font-family: 'Yantramanav', sans-serif;
    font-size: 18px;
    line-height: 1.2;
    color: $black; 
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}

h1, h2, h3, h4 {
    color: $cyan;
}

.highlight {
    color:#bbbbbb;
    font-size:40px;
    line-height: 1;
}

.button {
    @include button;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:20px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:20px;
    margin-bottom:10px;
}
.blue-text{
    color:$cyan;
}

.grid-layout{
    display: grid;
    padding:0;
    grid-gap: 2.5rem;
    grid-template-columns: repeat(3, 1fr);
    text-align: left;
    list-style: none;
    color:$cyan;
    font-weight: bold;
    font-size: 1rem;
    img { /* like team in mockup */
        margin-bottom: 10px;
        width:100%!important;
        height: 260px!important;
        object-fit: cover;
    }
    .pdf-download {
        img {
            height: auto!important;
        }
    }
}
.t-special {
    .grid-layout {
        li {
            padding: 0px 3rem;
        }
    }
}

/* ==========================================================================
   PDF Download
   ========================================================================== */
.pdf-download {
    position: relative;
    display:block;
    max-width: 100%;
    min-height: 80px;
    background-color: $grey;
    text-decoration: none;
    box-shadow: $drop-shadow;
    margin-bottom: 10px;
    &:before,
    &:after {
        position: absolute;
        z-index: 1;
        opacity: 0;
        transition: opacity 0.3s ease;
    }
    &::before {
        content:"";
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
    }
    &:after {
        content: "View catalogue";
        text-transform: uppercase;
        top: 50%;
        margin-top: -22px;
        left: 50%;
        margin-left: -40%;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        text-transform: uppercase;
        text-decoration: none;
        padding: 10px;
        width: 80%;
        color: #FFF;
        background-color: transparent;
        border: 1px solid #FFF;
        box-sizing: border-box;
    }
    img {
        width: 100%!important;
        margin: 0px;
    }
    &:hover {
        &:before,
        &:after {
            opacity: 1;
        }
    }
}

@media only screen and (max-width: 960px){
    .t-special .grid-layout {
        li {
            padding: 0px 4%;
        }
    }
    .highlight {
        font-size: 1.5em;
    }
}
@media only screen and (max-width: 760px){
    img.display-left,
    img.display-right {
        float: none;
        max-width: 100%;
        margin: 20px 0;
    }
    .grid-layout{
        grid-template-columns: auto auto;
    }
}
@media only screen and (max-width: 600px) {
    .grid-layout{
        grid-template-columns: auto;
    }
}
@media only screen and (max-width: 300px) {
    .button {
        min-width: 0px;
        width: 100%;
    }
}