// Site Colors
$blue : #193D8D;
$cyan : #238DC1;
$grey : #B2B2B2;
$white: #ffffff;
$black: #333333;
$orange : #F05A24;
$off-white: #F1F1F1;

// Text Colors
$grey-text: #808080;
$black-text: #4D4D4D;

// drop shadow
$drop-shadow: 0px 0px 30px -15px $black-text;

@mixin button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 9px 20px;
    max-width: 100%;
    min-width: 200px;
    color: $cyan;
    background-color: transparent;
    border: 1px solid $cyan;
    box-sizing: border-box;
    transition: outline 0.3s ease;
    margin: 2px;
    &:hover {
        outline: 1px solid $cyan;
    }
}